body {
  font-family: "Roboto";
}

.outline-text {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

/* --------------- Font Picker CSS -------------*/
div[id^=font-picker]>* {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
  border-radius: 4%;
}

div[id^=font-picker].expanded ul {
  margin-top: 2px;
  max-height: 300px !important;
}

div[id^=font-picker] ul li button {
  line-height: 1.5;
  padding: 5px;
  text-align: center;
  display: block !important;
}

div[id^=font-picker].expanded ul li button:hover {
  --tw-bg-opacity: 1;
  background: rgb(37 99 235 / var(--tw-bg-opacity))
}

div[id^=font-picker] .active-font {
  background: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}


/* --------- React Select CSS  -------------*/

.react-select-container * {
  /* background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
  outline: none;
  box-shadow: none !important;
  border: none !important;
  color: white !important;
  cursor: pointer; */
}

.react-select-container {
  /* margin-left: 5px !important; */
}

.react-select__control {
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
  border: none !important;
  box-shadow: none !important;
}

.react-select__placeholder {
  color: white !important;
}

.react-select__single-value {
  color: white !important;
}

.react-select__menu {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
  border-radius: 4%;
  min-height: 300px;
}

#react-select-2-listbox *:hover {
  cursor: pointer !important;
}

.react-select__option--is-selected {
  background: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.react-select__option--is-focused {
  background: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.react-select__option {
  background-color: none !important;
}

.react-select__input-container * {
  /* display: none; */
  /* visibility: hidden !important; */
  background: none !important;
}

.react-select__value-container {
  background: none !important;
}

.react-select__input {
  color: white !important;
}

/* --------- React Color Picker CSS  -------------*/

.sketch-picker {
  background: #334154 !important;
  margin: auto;
}

.sketch-picker * {
  color: white !important;
}


/* --------- Dropzone CSS  -------------*/